import ShiftsGrid from '../components/ShiftsGrid';
import usePlantsRoute from '../hooks/usePlantsRoute';
import { useDeleteShiftData, useGetShiftData, useSaveShiftData } from '../hooks/api';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { format } from 'date-fns';
import { dateDtoValues, shiftLengthCalc } from '../hooks/date';
import { useUser } from '../contexts/UserProvider';
import { hasPlantAccess, readOnlyGroup } from '../components/utils';

export default function Shifts() {
  const { user } = useUser();
  const { id: plantId } = usePlantsRoute();
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [dateRange, setDateRange] = useState([null, null]);
  const filterParams = useMemo(() => {
    const filters = {
      page: page,
      plantId: plantId,
    };
    if (dateRange[0]) {
      filters.fromDate = dateRange[0];
    }
    if (dateRange[1]) {
      filters.toDate = dateRange[1];
    }

    return filters;
  }, [plantId, page, dateRange]);
  const { isLoading, isError, error, data } = useGetShiftData(filterParams);
  const [snackbar, setSnackbar] = useState(null);
  const handleCloseSnackbar = useCallback(() => setSnackbar(null), [setSnackbar]);
  const saveShiftData = useSaveShiftData();
  const onRowSave = useCallback(async (row) => {
    try {
      // This client side check is also done on the server side.
      if (user.groups.includes(readOnlyGroup)) {
        setSnackbar({ children: 'You do not have permission to save shifts', severity: 'error' });
        return null;
      }

      const { date, ...otherCells } = row;
      const dto = dateDtoValues(otherCells);
      dto.plantId = plantId;
      dto.date = format(date, 'yyyy-MM-dd');
      dto.shiftLength = shiftLengthCalc({ row: dto });
      const { id } = await saveShiftData.mutateAsync(dto);
      setSnackbar({ children: 'Shift successfully saved', severity: 'success' });
      return id;
    } catch (e) {
      setSnackbar({ children: e.message, severity: 'error' });
      return null;
    }
  }, [plantId, saveShiftData, user.groups]);
  const deleteShiftData = useDeleteShiftData();
  const onRowDelete = useCallback(async (id) => {
    try {
      // This client side check is also done on the server side.
      if (user.groups.includes(readOnlyGroup)) {
        setSnackbar({ children: 'You do not have permission to delete shifts', severity: 'error' });
        return false;
      }

      await deleteShiftData.mutateAsync({
        plantId,
        id,
      });
      setSnackbar({ children: 'Shift successfully deleted', severity: 'success' });
      return true;
    } catch (e) {
      setSnackbar({ children: e.message, severity: 'error' });
      return false;
    }
  }, [plantId, deleteShiftData, user.groups]);

  useEffect(() => {
    if (data) {
      setRows(data.rows);
      setRowCount(data.rowCount);
      setPageSize(data.pageSize);
    }
  }, [data]);

  if (!hasPlantAccess(user, plantId)) {
    return <Alert severity='error'>You are not authorized to view this page</Alert>;
  }

  return (
    <>
      <h1>Shifts in { plantId }</h1>
      <div className='grid-wrapper'>
        <div className='grid-container'>
          { isError && <Alert severity='error'> { error.message } </Alert>}
          { <ShiftsGrid
              rows={rows}
              setRows={setRows}
              rowCount={rowCount}
              setPage={setPage}
              page={page}
              pageSize={pageSize}
              setDateRange={setDateRange}
              isLoading={isLoading}
              onRowSave={onRowSave}
              onRowDelete={onRowDelete}
            />
          }
        </div>
      </div>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}

    </>
  );
}
